import { NotificationConsumer as IbeNotificationConsumer } from "ibe-components"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import featureFlags from "../../../constants/feature-flags"
import { TabNavbar } from "../../organisms/Navbar"
import PageLayout from "../PageLayout"

const screenWidth = window.innerWidth < 400

const NotificationConsumer = ({ render, beforeTab = () => null, pageLayoutBackground }) => {
  const tabItems = [
    {
      to: "/",
      matches: ["/"],
      title: screenWidth ? "Book" : "Book Trip",
    },
    {
      to: "/trips",
      matches: ["/trips", "/trips/manage", "/trips/details"],
      title: screenWidth ? "Trips" : "My Trips",
    },
    {
      to: "/profile",
      matches: ["/profile", "/login", "/account/register"],
      title: screenWidth ? "Profile" : "My Profile",
    },
  ]

  return (
    <IbeNotificationConsumer>
      {({ actions }) => {
        return (
          <PageLayout
            background={pageLayoutBackground}
            render={() => {
              return (
                <>
                  <TabNavWrapper>
                  {beforeTab()}
                    <TabNavbar items={tabItems} />
                    {render({ flash: actions })}
                  </TabNavWrapper>
                </>
              )
            }}
          />
        )
      }}
    </IbeNotificationConsumer>
  )
}

const TabNavWrapper = styled.div`
  border: none;
  height: 100%;
`
export default NotificationConsumer

NotificationConsumer.propTypes = {
  pageLayoutBackground: PropTypes.string,
  render: PropTypes.element.isRequired,
  beforeTab: PropTypes.func,
}

NotificationConsumer.defaultProps = {
  pageLayoutBackground: "#F5F5F5",
  beforeTab: () => {},
}
