import * as React from "react"
import { useState } from "react"
import AirCanadaLayout from "./ACLayout"
import AirCanadaNavigation from "./AirCanadaNavigation"
import AirCanadaContent from "./ACContent"
import QuestionAndAnswer from "./questionAndAnswer"
import * as styles from "./AirCanada.module.scss"


const AirCanadaPage = () => {
  const [loadedPixel, setLoadedPixel] = useState(false)
  const faqData = [
    {
        q: "1. What is Landline?",
        a: <p>Landline is the industry leader in providing seamless air-to-ground connectivity. Landline’s luxury motorcoaches allow Air Canada to leverage the seamlessness and affordability of ground transportation to add dynamic new routes to their network without sacrificing on quality or customer experience.</p>
    },
    {
        q: "2. How do I book a Landline itinerary?",
        a: <p>Landline/Air Canada itineraries are booked like any other flight in the Air Canada network. Book on aircanada.com from Hamilton (YHM) or Kitchener/Waterloo (YKF) to your final destination (e.g. Hamilton (YHM) to Vancouver (YVR)).</p>
    },
    {
        q: "3. Can I book just the Landline segment?",
        a: <p>Landline works as a seamless connection to your Air Canada flight through Toronto Pearson. You are not able to book Landline independently of your flight.</p>
    },
    {
        q: "4. Can I choose my seat?",
        a: <p>Yes, you can choose your seat on the Landline segment.</p>
    },
    {
        q: "5. What is the bag policy?",
        a: <p>Air Canada checked bag policies apply to Landline trips. Please visit the Air Canada bag policy <a href="https://www.aircanada.com/us/en/aco/home/plan/baggage.html">here</a> for more information and pricing. Please note that carry on items that do not fit under the seat or in overhead bins may be placed underneath the bus and returned to you upon arrival.</p>
    },
    {
        q: "6. How do checked bags work?",
        a: <p>For Landline trips originating in Hamilton (YHM) or Kitchener/Waterloo (YKF), simply check your bag with the Air Canada agent at the counter and leave the rest to us. Upon arrival at Pearson airport, Air Canda ground staff will automatically transfer your bags to the aircraft. For Air Canada flights arriving at Pearson airport and connecting to an onward Landline segment, please pick your baggage up from baggage claim in Toronto (YYZ) and bring your bag to the motorcoach.</p>
    },
    {
        q: "7. How do I check in?",
        a: <p>You’ll check in for your journey like you would for any Air Canada flight. You can either check in on aircanada.com or on the Air Canada app up to 24 hours before your flight, or you can check in at your departure airport up to 30 minutes before departure.</p>
    },
    {
        q: "8. Will I have a boarding pass?",
        a: <p>Yes, you will receive a boarding pass upon check in for your segment to Toronto Pearson, as well as your onward connecting flight.</p>
    },
    {
        q: "9. Are pets allowed on the motorcoach?",
        a: <p>Landline accepts carry-on pets that fit in airline approved carriers as well as service animals. Air Canada pet policies apply to Landline segments. Please visit the link <a href="https://www.aircanada.com/us/en/aco/home/plan/special-assistance/pets.html#/">here</a> for more information. If you are traveling with a service animal, please visit the accessibility page <a href="https://www.aircanada.com/us/en/aco/home/plan/accessibility.html">here</a>.</p>
    },
    {
        q: "10. Is parking available?",
        a: <p>Yes, parking is available for a fee at both Hamilton (YHM) and Kitchener/Waterloo (YKF), For additional information, please contact the airports directly.</p>
    },
    {
        q: "11. How far ahead do I need to be at my Landline pick-up location?",
        a: <p>To allow time for baggage drop-off and other airport processes, you’ll need to arrive at your Landline motorcoach pick-up location well ahead of the scheduled departure time. For journeys starting at John C. Munro Hamilton International Airport (YHM) or Region of Waterloo International Airport, please arrive at the airport no later than 30 minutes ahead of scheduled departure time. For customers departing Toronto Pearson International Airport (YYZ), please arrive at the pick-up location no later than 15 minutes ahead of scheduled departure time.</p>
    },
    {
        q: "12. How much time do I need to connect in Toronto to/from a Landline segment?",
        a: <p>Connecting times in Toronto are already planned for at time of booking your Air Canada journey and built into your itinerary.</p>
    },
    {
        q: "13. Where do I go to board my Landline vehicle?",
        a: <p>In Hamilton and Kitchener/Waterloo, Landline departs to Toronto Pearson from the curb in front of the check-in area. At Toronto Pearson, Landline departs to Hamilton and Kitchener/Waterloo will depart from Ground Level. Please refer to signage when exiting baggage claim.</p>
    },
    {
        q: "14. What if my Landline vehicle is delayed and I miss my connecting flight?",
        a: <p>Please see an Air Canada representative at Toronto Pearson to be rebooked free of charge on a later flight.</p>
    },
    {
        q: "15. What happens if my flight is delayed, and I miss my Landline departure?",
        a: <p>If you miss your Landline departure, please see an Air Canada representative at Toronto Pearson to be rebooked on a later trip.</p>
    },
    {
        q: "16. What amenities are available on board?",
        a: <p>Every Landline vehicle features 2x1 configured reclining leather seats with in-seat power and a sturdy tray table. During your drive you’ll also enjoy free Wi-Fi. The vehicle is also equipped with a lavatory.</p>
    },
    {
        q: "17. Will there be food/drink available?",
        a: <p>While the Landline segment of your journey does not offer food and beverage service, you are welcome to bring your own snacks on board to enjoy.</p>
    },
    {
        q: "18. Are the vehicles handicap accessible?",
        a: <p>All Landline vehicles are handicap accessible; Landline can accommodate passengers who need to be seated in their wheelchairs. Please contact Air Canada reservations to request accommodations.</p>
    },
    {
        q: "19. Will I earn Aeroplan miles on the Landline segment?",
        a: <p>Yes, you will earn redeemable miles and SQMs/SQSs/SQDs on Landline departures.</p>
    },
  ]

  const handleBookingNavigation = React.useCallback(async () => {
    try {
      await fetch(
        `https://i.ctnsnet.com/int/integration?pixel=69567451&nid=66354764&cont=i`,
        { mode: "no-cors" }
      )
    } catch (e) {
      // Ignoring error, just going to redirect anyway
    } finally {
      window.location = "https://www.aircanada.com/"
    }
  }, [])

  return (
    <>
        <script
          type="text/javascript"
          src="//cdn.rlets.com/capture_configs/32a/0d7/bb6/5f6460ca17163f12a81c396.js"
          async
        ></script>
        <script
          src="https://consentag.eu/public/3.0.1/consenTag.js"
          type="text/javascript"
          async
        ></script>
      <AirCanadaLayout>
        <div className={styles.hero}>
          <div className={styles.heroContent}>
            <h2 className={styles.heroTitle}>Conveniently connecting passengers to Toronto Pearson Airport</h2>
            <p className={styles.heroText}>
              Start your journey closer to home. Search Hamilton (YHM) and Kitchener (YKF) to 140+ destinations with Air Canada.
            </p>
            
            <div className={styles.bookNowBtn}>
              <a
                href="https://www.aircanada.com/"
                className={styles.bookNowLink}
                onClick={handleBookingNavigation}
              >
                Book Now
              </a>
            </div>
          </div> 
        </div>
        <AirCanadaNavigation />
        <AirCanadaContent />
        <div className={styles.faqSection} id="AirCanadaFAQs">
          <div className={styles.faqContent}>
            <h3 className={styles.faqTitle}>FAQs</h3>
            {faqData.map(item => {
              return (
                <QuestionAndAnswer
                  item={item}
                  questionBgColor="airCanadaRed"
                ></QuestionAndAnswer>
              )
            })}
          </div>
        </div>
      </AirCanadaLayout>
    </>
  )
}

export default AirCanadaPage