import React, { useState } from "react";
import SchedulePage from "..";
import ScheduleHeader from "../Header";
import OurService from "../OurService";
import ModuleTitle from "../../../atoms/SchedulePages/ModuleTitle";
import { PickupLocations, PickupLocation } from "../Locations";
import { Schedule, ScheduleTable } from "../Schedule";
import Search from "../../HomePage/Search";

import Pickup_Locations from "./pickup-locations";
import { LaCrosseWeeklyData, generateLaCrosseData } from './schedule';
import { LaCrosseMeta, LaCrosseParking, LaCrosseService } from "./metadata";

import Parking from "../Parking";
import './styles.scss';


const LaCrosseSchedule = ({ history }) => {
    const [selectedWeek, setSelectedWeek] = useState(LaCrosseWeeklyData[0]);
    const [schedule, setSchedule] = useState(() => 
        generateLaCrosseData(LaCrosseWeeklyData[0])
    );

    const handleWeekChange = (week) => {
        setSelectedWeek(week);
        setSchedule(generateLaCrosseData(week));
    };

    const currentIndex = LaCrosseWeeklyData.findIndex(
        week => week.start === selectedWeek.start && week.end === selectedWeek.end
    );

    const handlePrevious = () => {
        if (currentIndex > 0) {
            handleWeekChange(LaCrosseWeeklyData[currentIndex - 1]);
        }
    };

    const handleNext = () => {
        if (currentIndex < LaCrosseWeeklyData.length - 1) {
            handleWeekChange(LaCrosseWeeklyData[currentIndex + 1]);
        }
    };

    return (
        <SchedulePage>
            <div className="schedule-search-form">
                <Search history={history} remoteCallSearchComponent={true} />
            </div>
            <div className="schedule-page">
                <p className="startdatepill">Starts April 3</p>
                <ScheduleHeader metadata={LaCrosseMeta.header} />

                <ModuleTitle title="Schedule" />
                <p className="schedule-text">The schedule below reflects the week selected, and is subject to change. The most up-to-date departure and arrival times can be seen at the time of <a href="/">booking</a>.</p>
                <div className="week-selector">
                    <button 
                        onClick={handlePrevious}
                        disabled={currentIndex === 0}
                        className="nav-button"
                        aria-label="Previous week"
                    >
                        <svg 
                            width="24" 
                            height="24" 
                            viewBox="0 0 24 24" 
                            fill="none" 
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path 
                                d="M15 18L9 12L15 6" 
                                stroke="currentColor" 
                                strokeWidth="2" 
                                strokeLinecap="round" 
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    
                    <div className="week-display">
                      <span className="week-date">
                          Week of {new Date(selectedWeek.start).toLocaleDateString('en-US', { 
                              month: 'long', 
                              day: 'numeric' 
                          })}
                      </span>
                      <span className="week-count">
                          {new Date(selectedWeek.start).toLocaleDateString('en-US', { 
                              month: 'numeric', 
                              day: 'numeric'
                          })} to {new Date(selectedWeek.end).toLocaleDateString('en-US', { 
                              month: 'numeric', 
                              day: 'numeric'
                          })} 
                      </span>
                  </div>

                    <button 
                        onClick={handleNext}
                        disabled={currentIndex === LaCrosseWeeklyData.length - 1}
                        className="nav-button"
                        aria-label="Next week"
                    >
                        <svg 
                            width="24" 
                            height="24" 
                            viewBox="0 0 24 24" 
                            fill="none" 
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path 
                                d="M9 6L15 12L9 18" 
                                stroke="currentColor" 
                                strokeWidth="2" 
                                strokeLinecap="round" 
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>
                
                <Schedule>
                    <ScheduleTable schedule={schedule.from} />
                    <ScheduleTable schedule={schedule.to} />
                </Schedule>

                <PickupLocations>
                    {Pickup_Locations.map((location) => (
                        <PickupLocation key={location.id} location={location} />
                    ))}
                </PickupLocations>

                <Parking data={LaCrosseParking} />

                <OurService service={LaCrosseService} />
            </div>
        </SchedulePage>
    );
};

export default LaCrosseSchedule;