import { PageTitle } from "ibe-components"
import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"

import errorActions from "../../../redux/error/actions"
import { errorSelector, tripSelector } from "../../../redux/selector"
import tripActions from "../../../redux/trip/actions"
import { reduceErrors } from "../../../utils/common"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import Loading from "../../atoms/Loading"
import TabNavbarLayout from "../../layouts/TabNavbarLayout"
import TripManageForm from "../../organisms/ManageForm"

const ManageTripsPage = () => {
  const trip = useSelector(state => tripSelector(state))
  const error = useSelector(state => errorSelector(state))
  const dispatch = useDispatch()
  const checkReservation = useCallback(params => dispatch(tripActions.checkReservation(params)), [
    dispatch,
  ])
  const clearError = useCallback(() => dispatch(errorActions.clearError()), [dispatch])

  const [state, setState] = useState({
    form: {
      last_name: "",
      pnr: "",
      modalErrors: [],
    },
    goToDetail: false,
  })

  const changeState = params => {
    setState(prevState => ({
      ...prevState,
      ...params,
    }))
  }
  const onInputChanged = field => value => {
    setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [field]: value,
      },
    }))
  }
  const onSubmit = e => {
    e.preventDefault()
    const {
      form: { last_name: lastName, pnr },
    } = state
    checkReservation({ last_name: lastName, pnr })
    changeState({ goToDetail: true })
  }

  if (state.goToDetail && trip.detail) {
    return <Redirect push to="/trips/details" />
  }

  return (
    <>
      <ErrorBoundary>
        <TabNavbarLayout
          render={({ flash }) => {
            if (trip.isBusy || (error && error.isBusy)) {
              return <Loading />
            }
            if (error) {
              let errors = {}
              if (typeof error === "string") {
                errors[0] = error
              } else {
                errors = Object.keys(error).reduce((pr, cv) => {
                  pr[cv] = `${cv.toUpperCase()}: ${error[cv]}`
                  return pr
                }, {})
              }
              if (errors && Object.keys(errors).length > 0) {
                setState(prevState => ({
                  ...prevState,
                  modalErrors: reduceErrors(errors),
                  formErrors: errors,
                }))
              }
            }
            if (state.modalErrors) {
              clearError()
              setState(prevState => ({ ...prevState, modalErrors: undefined }))
            }

            return (
              <div className="SectionWrapper" style={{ padding: 20 }}>
                <PageTitle title="Locate your trip" margin="0 0 20px 0" />
                <TripManageForm
                  inputs={state.form}
                  error={state.formErrors}
                  onInputChanged={onInputChanged}
                  onSubmit={onSubmit}
                />
              </div>
            )
          }}
        />
      </ErrorBoundary>
    </>
  )
}

export default ManageTripsPage
