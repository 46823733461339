import Map from "../LaCrosse/LSE Parking Map.png"


import React from "react"

const LaCrosseMeta = {
    header: {
        title: "La Crosse Airport Shuttle",
        subtitle: "La Crosse, WI | Onalaska, WI | La Crescent, MN",
        description: "Landline departs promptly at the scheduled time. Please check in at the Landline/Sun Country counter inside the terminal. \nYour driver or Landline agent will announce when it is time to board the motorcoach to Minneapolis-St. Paul International Airport.  \nPlease arrive early and have your ID ready to ensure an on time departure.",
        book: true
    }
}

const LaCrosseService = <>
    <p>Let Landline’s premium airport shuttle service from LaCrosse (LSE) and Minneapolis St. Paul International Airport (MSP) be the best part of your travel day.</p>
    <p>Avoid the stress and expense of driving, parking or ridesharing and instead opt for Landline’s comfortable, Wi-Fi enabled vehicles.</p>
    <p>Enjoy modern conveniences like air conditioning, in seat power, reclining leather seats, onboard restroom, and get caught up on work or stream your favorite shows while you ride.</p>
    <p>Our friendly staff will help you with loading and unloading your luggage on both ends of your trip ensuring a smooth and efficient trip.</p>
    <p>Landline is ideal for families, college students, leisure and business travelers alike looking for a safe, reliable and an affordable way to/ from MSP.</p>
</>

const LaCrosseParking = {
    title: "LaCrosse Airport Parking",
    image: Map,
    content: <>
        <p>La Crosse Airport has options for both short and long term parking. Please visit their website <a href="https://lseairport.com/parking/" target="_blank" rel="noopener noreferrer">here</a>, to learn more about rates, payment options and more. If you have additional questions about parking, please contact the La Crosse Regional Airport directly. </p>
    </>
}


export {
    LaCrosseMeta, LaCrosseParking, LaCrosseService
}