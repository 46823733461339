import React from "react"
import "./FAQ.scss"

import BasicPage from "../BasicPage"

const FAQPage = () => {

  const metadata = {
    title: "Riding with us in Minnesota or Colorado?",
    subtitle: "You've got questions. We have answers."
  }

  const FAQs = [
    {
      title: "General Questions",
      questions: [
        {
          question: "Where does Landline operate?",
          answer: <p><strong>DIA:</strong> Landline operates airport shuttle service at Denver International to/from <a href="/fort-collins">Ft. Collins/Loveland, CO</a>.<br /> <strong>MSP:</strong> Landline operates airport shuttle service at Minneapolis/St. Paul International to/from <a href="/duluth/">Duluth,MN</a>, <a href="/fargo-airport-shuttle">Fargo, ND</a>.</p>
        },
        {
          question: "Can I use Landline if I'm not actually flying with an airline?",
          answer: <p>Absolutely! Anyone can enjoy Landline's low fares, first class amenities and fantastic service.</p>
        },
        {
          question: "What if I booked directly with Sun Country or United?",
          answer: <p>If you booked your travel with Sun Country, please refer to <a href="https://suncountry.com/landline" target="_blank" rel="noreferrer noopener">https://Suncountry.com/landline</a>. If you booked your travel with United, please refer to <a href="https://www.united.com/en-us/landline" target="_blank" rel="noreferrer noopener">https://United.com/en-us/landline</a>.</p>
        },
        {
          question: "Can Landline accommodate special travel needs?",
          answer: <p>Yes, we are able to provide special travel assistance. This assistance includes steadying a customer or providing a helping hand as the customer ascends or descends the stairs, assistance in getting to or from the seat, and assistance with loading and retrieving carry-on items and other assistive devices stowed on board. Employees are not permitted, however, to lift or carry customers on board the vehicle or assist with medical services. As the safest seating arrangement for such customer may vary with the situation and with the vehicle type, Landline may offer to pre-board the customer or may suggest boarding the customer last for access to the most accessible seat. Some vehicles are equipped with ADA compliant lifts and seating. When making your booking, simply select “Wheelchair Assistance” prior to checkout. In addition, to accommodate each passengers requests, we ask any special service requests be made by contacting our customer service team at least 48 hours in advance by calling&nbsp;<a href="tel:18884281149" target="_blank" rel="noreferrer noopener">1-888-428-1149</a>&nbsp;or emailing&nbsp;<a href="mailto:info@landline.com" target="_blank" rel="noreferrer noopener">info@landline.com</a>&nbsp;with details of the request.</p>
        },
        {
          question: "Are there any baggage fees or restrictions?",
          answer: <p>Landline does not have any baggage fees and each passenger is initially allowed one checked bag, one carry-on item and a small personal item. Any additional baggage is subject to space availability. The dimensions of checked items may not exceed 90 inches in overall length, width and height. Checked baggage must also weigh less than 50 lbs.<br />

            All passengers are prohibited from carrying weapons on the bus or in carry-on baggage. Federal or local law enforcement officers are subject to exemptions. Weapons and firearms may be transported in checked baggage. Firearms in checked baggage must be in a locked hard-sided case and reported to the Landline representative at check-in.</p>
        },
        {
          question: "Do you allow pets to travel with Landline?",
          answer: <p>Yes, pets can travel on Landline. They must fit in a small, ventilated container that can be stowed in the cabin. Service animals or support animals can be seated on a passenger's lap or in the floor space located at the passenger's seat. Please email us at <a href="mailto:info@landline.com">info@landline.com</a>, submit a ticket via the chat widget or call us at <a href="tel:1-888-428-1149">1-888-428-1149</a> if you have any additional questions.</p>
        },
        {
          question: "Does Landline require car seats for small children?",
          answer: <p>While it is not legally required on Landline vehicles, we highly recommend bringing a car seat if you are traveling with a small child. Our seats have belt restraints and are car seat compatible. Landline is unable to assist with installation of provided car seats.</p>
        },
        {
          question: "What are the age requirements for a young person traveling alone?",
          answer: <p>In CO, someone as young as 15 can travel alone on our service. In MN, someone as young as 14 can travel alone on our service.</p>
        },
        {
          question: "Do I need to check-in prior to boarding? Will I receive a boarding pass?",
          answer: <p>You will not be required to check-in online prior to boarding and boarding passes are not required. You can check-in directly with your driver by showing your ID.</p>
        },
        {
          question: "What time should I arrive prior to departure for my Landline trip?",
          answer: <p>If you are traveling with checked baggage, please arrive 25 minutes prior to departure. If you are traveling with no checked baggage, please arrive 20 minutes prior to departure.</p>
        },
        {
          question: "Do you have short to long term parking available for Landline passengers? If so, what are the parking rates?",
          answer: <div><p>Landline has partnered with several hotels and airports to offer passengers affordable parking options. Parking rates will vary by airport and pick up / drop off locations.</p>
            <ul>
              <li>Duluth International Airport: 4701 Grinden Dr, Duluth, MN at <a href="https://duluthairport.com/before-you-arrive/parking/rates/" target="_blank" rel="noreferrer noopener">airport rates</a>.</li>
              <li>Fargo: Hector International Airport in Fargo, ND at <a href="https://www.fargoairport.com/at-the-airport/parking/" target="_blank" rel="noreferrer noopener">airport rates</a>.</li>
              <li>Northern Colorado Regional Airport / Fort Collins Loveland Airport: 4825 Earhart Rd, Loveland, CO - Free Parking for three weeks. Visit <a href="https://www.flynoco.com/faq/">https://www.flynoco.com/faq/</a> or call <a href="tel:970-962-2862">970-962-2862</a> for parking pass inquiries.</li>
            </ul></div>
        },
        {
          question: "What should I do if my flight is changed or canceled?",
          answer: <div><p>If you booked your trip directly with Landline, you can edit or cancel your trip at any time by going to <a href="https://landline.com/landline/trips">manage my trip</a> on our website or by contacting us at <a href="tel:1-888-428-1149">1-888-428-1149</a>.</p><p>If you booked directly with a partner airline, please contact the airline to edit or cancel your trip as Landline does not have access to edit partner airline travel itineraries.</p><ul><li>For United Airlines call <a href="tel:1-800-864-8331">(800) 864-8331</a></li><li>For American Airlines call <a href="tel:1-800-433-7300">(800) 433-7300</a></li><li>For Sun Country Airlines call (<a href="tel:1-651-905-2737">651) 905-2737</a></li></ul></div>
        },
        {
          question: "Can I cancel my reservation?",
          answer: <p>Yes, all Landline tickets may be canceled at any time prior to your trip. Canceled tickets are converted into credits for future travel on Landline. If your booking does not show in your Landline&nbsp;<a href="https://landline.com/landline/trips" target="_blank" rel="noreferrer noopener">account here</a>, please email&nbsp;<a href="mailto:info@landline.com" target="_blank" rel="noreferrer noopener">info@landline.com</a>&nbsp;or text/call us at&nbsp;<a href="tel:18884281149" target="_blank" rel="noreferrer noopener">1-888-428-1149</a>.”</p>
        },
        {
          question: "If I cancel my Landline reservation, will I get a refund?",
          answer: <p>Per Landline's cancellation policy, all ticket reservations are non-refundable. Tickets that are booked while logged into a Landline account are automatically credited to the account when cancelled. Tickets not booked while logged into an account, or prior to the accounts creation can be converted into credits for future travel by speaking with a member of our team. To receive an eligible credit, please email&nbsp;<a href="mailto:info@landline.com" target="_blank" rel="noreferrer noopener">info@landline.com</a>&nbsp;or call us at&nbsp;<a href="tel:18884281149" target="_blank" rel="noreferrer noopener">1-888-428-1149</a>.</p>
        }
      ]
    },
    {
      title: "About Colorado Service",
      questions: [
        {
          question: "Where are the pick-up and drop-off locations for Landline at DEN?",
          answer: <div >
            <p>We drop off all Landline passengers at doors 506 for baggage check-in and TSA security.</p>
            <p>For pick up, if you booked directly with Landline, we pick up curbside on the <strong>West side of level 5 <strong>directly outside doors</strong> 500 - 50</strong>4 (not on an island) near United Airlines baggage claim. </p>
            <p>For passengers who booked Landline service with United Airlines, we pick up at Gate B83.</p>
            <p><strong>If you booked your travel with United</strong>, please refer to <a href="https://www.united.com/en-us/landline" target="_blank" rel="noreferrer noopener">https://United.com/en-us/landline</a>.</p>
          </div>
        },
        {
          question: "Which side of the airport does my airline depart from in DEN?",
          answer: <p>
            The West Side of the terminal serves Air Canada, Allegiant, American Airlines, Delta Air Lines, Lufthansa, Spirit Airlines, Sun Country Airlines, United Airlines, and West Jet. <br />The East Side of the terminal serves Alaska Airlines, Boutique Air, Denver Air Connection, Frontier, JetBlue Airways, Southern Airways Express, Southwest Airlines, and Volaris.
          </p>
        }
      ]
    },
    {
      title: "About Minnesota Service",
      questions: [
        {
          question: "Does Landline serve both terminals at MSP?",
          answer: <div>
            <p>Yes, Landline provides service to Terminal 1 (Lindbergh) at the Silver Parking ramp and Terminal 2 (Humphrey). At Terminal 2 (Humphrey), Landline picks up and drops off at the ground transportation center across from the baggage claim.&nbsp;</p>
            <p><strong>If you booked your travel with Sun Country,</strong> please refer to <a href="https://suncountry.com/landline" target="_blank" rel="noreferrer noopener">https://Suncountry.com/landline</a>.</p>
          </div>
        },
        {
          question: "Which terminal does my airline depart from in MSP?",
          answer: <p>Terminal 1 Lindbergh serves Aer Lingus, Air Canada, Air Choice One, Air France, Alaska Airlines, American Airlines, Boutique Air, Delta Air Lines, KLM Royal Dutch Airlines, Spirit Airlines, and United Airlines. <br />Terminal 2 Humphrey serves Condor, Frontier Airlines, Icelandair, JetBlue Airways, Southwest Airlines, and Sun Country Airlines. <br />We serve both terminals! In case you forget your terminal, don't hesitate to ask your driver or a Landline representative.</p>
        }
      ]
    }
  ]

  return (
    <BasicPage>
      <div className="faq-page">
        <div className="faq-header">
          <h2>{metadata.title}</h2>
          <h5>{metadata.subtitle}</h5>
        </div>
        <div className="faq-sections">
          {FAQs.map((section) => {
            return (
              <div className="faq-section">
                <h3>{section.title}</h3>
                <a href="#" className="faq-back">Back to Top</a>
                {section.questions.map((item) => {
                  return (
                    <div className="faq-question">
                      <h4>{item.question}</h4>
                      {item.answer}
                    </div>
                  )
                })}
              </div>
            )
          })}

        </div>

      </div>
    </BasicPage>
  )
}

export default FAQPage
