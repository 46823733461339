import Map from "../LaCrosse/LSE Pick Up Map.png"


const Pickup_Locations = [
  {
    image: Map,
    title: "La Crosse Regional Airport (LSE)",
    prices: "Adults: $39 and up  |   Kids: FREE (12 & under)",
    link: "https://www.google.com/maps/place/La+Crosse+Regional+Airport/@43.8753572,-91.2661329,17z/data=!3m1!4b1!4m6!3m5!1s0x87f95391f25d8cf3:0x72258f9b90ce103f!8m2!3d43.8753534!4d-91.263558!16zL20vMDd0eW56?entry=ttu&g_ep=EgoyMDI1MDExMC4wIKXMDSoASAFQAw%3D%3D",
    top: "Landline picks up in front of the main terminal lobby of the La Crosse Regional Airport (LSE)",
    bottom: "Please check in at the Landline/Sun Country counter inside the terminal. There is a waiting area inside the terminal for your convenience. A Landline agent will announce when it is time for boarding. "
  }
]

export default Pickup_Locations