import React from "react"
import "./AboutUs.scss"

import BasicPage from "../BasicPage"

const AboutUsPage = () => {
  const metadata = {
    title: "Our Landline Values",
    subtitle: "Landline offers the best connection to your flight at an incredible price",
  }

  const benefits = [
    {
      image: "https://content.landline.com/wp-content/uploads/2025/01/aboutus1.png",
      title: "Comfort",
      subtitle: "Our vehicles are uniquely designed to make travel more relaxing and comfortable",
    },
    {
      image: "https://content.landline.com/wp-content/uploads/2025/01/aboutus2.png",
      title: "Convenience",
      subtitle:
        "Our nonstop service to/from MSP in Minnesota and DEN in Colorado is fast, easy, and stress free",
    },
    {
      image: "https://content.landline.com/wp-content/uploads/2025/01/aboutus3.png",
      title: "Value",
      subtitle: "Landline offers travel without the high cost",
    },
    {
      image: "https://content.landline.com/wp-content/uploads/2025/01/aboutus4.png",
      title: "Service",
      subtitle:
        "Friendly agents at pick up / drop off locations are there to make your trip a success",
    },
  ]

  const carriers = [
    {
      title: "Flying with Sun Country?",
      steps: [
        "Go to suncountry.com to book.",
        "When searching for your trip, make sure your search originates in one of the cities we serve.",
        "Purchase your combined Landline and Sun country trip.",
        "Check in for your trip online or in person at the Landline terminal.",
        "Drop your luggage off with a Landline employee; luggage will be checked",
        "Arrive at MSP and walk into the terminal to your Sun Country flight.",
      ],
      button: {
        title: "Book Now on Sun Country",
        url: "https://suncountry.com/",
      },
    },
    {
      title: "Flying with United?",
      steps: [
        "Go to united.com to book.",
        "When searching for your trip, make sure your search originates in one of the cities we serve.",
        "Purchase your combined Landline and United trip.",
        "Check in for your trip online or in person at the Landline terminal.",
        "Drop your luggage off with a Landline employee; luggage will be checked",
        "Arrive at DEN and walk into the terminal to your United flight.",
      ],
      button: {
        title: "Book Now on United",
        url: "https://united.com/",
      },
    },
  ]

  return (
    <BasicPage>
      <div className="about-us-container">
        <div className="about-us-headline">
          <h3>{metadata.title}</h3>
          <h4>{metadata.subtitle}</h4>
        </div>
        <div className="about-us-benefits">
          {benefits.map(benefit => {
            return (
              <div className="benefit-item">
                <img src={benefit.image} alt="" srcset="" />
                <h4>{benefit.title}</h4>
                <p>{benefit.subtitle}</p>
              </div>
            )
          })}
        </div>
        <div className="about-us-carriers">
          {carriers.map(carrier => {
            return (
              <div className="carrier-item">
                <h4>{carrier.title}</h4>
                <ol>
                  {carrier.steps.map(step => {
                    return <li>{step}</li>
                  })}
                </ol>
                <div className="carrier-action">
                  <a href={carrier.button.url}>{carrier.button.title}</a>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </BasicPage>
  )
}

export default AboutUsPage
