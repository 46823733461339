import * as React from "react"
import * as styles from "./ACContent.module.scss"

const AirCanadaContent = () => {
  return (
    <div className={styles.AirCanadaContent} id="AirCanadaContent">
      <h2 className={styles.AirCanadaContentTitle} id="AirCanadaHowItWorks">How It Works</h2>
      <div className={styles.AirCanadaContentOverview}>
          <p>Air Canada has partnered with Landline to offer more options for Hamilton and Kitchener passengers to connect through Toronto onward to 140+ destinations worldwide. The luxury ground transportation connection works just like a flight.</p>
        </div>
      <div className={styles.AirCanadaContentContent}>
        <div className={styles.AirCanadaContentDesc}>
          <h3>From Hamilton/Kitchener</h3>
          <ul>
            <li>Search trips from Hamilton (YHM)/Kitchener(YKF) to your final destination.</li>
            <li>Book your trip as you normally would. Connection through Toronto will show “operated by The Landline Company”.</li>
            <li>Check in for your trip 24 hours prior, same as you would for your flight</li>
            <li>Check your luggage to your final destination in Hamilton/Kitchener.</li>
            <li>Sit back and relax on Air Canada’s premium motorcoach service to Toronto.</li>
            <li>Go through airport screening in Toronto and continue to your gate to connect to your flight.</li>
            <li>Arrive at you final destination and collect your bags.</li>
          </ul>
        </div>
        <div className={styles.AirCanadaContentDesc}>
          <h3>To Hamilton/Kitchener</h3>
          <ul>
            <li>Search trips to Hamilton/Kitchener.</li>
            <li>Book your trip as you normally would. Connection through Toronto will show “operated by The Landline Company”.</li>
            <li>Check in for your trip 24 hours prior, same as you would for your flight</li>
            <li>Check your luggage and enjoy your flight to Toronto.</li>
            <li>Collect your bags from baggage claim in Toronto and proceed to the Landline pick-up location.</li>
            <li>Sit back and relax on Air Canada’s premium motorcoach service to Hamilton/Kitchener.</li>
          </ul>
        </div>
      </div>
      <h2 className={styles.AirCanadaContentTitle} id="AirCanadaLocations">Locations</h2>
      <div className={styles.AirCanadaContentContent}>
        <div className={styles.AirCanadaContentDesc}>
          <h3>Hamilton (YHM)</h3>
          <p>
          Landline picks up and drops off at the John C. Munro Hamilton International Airport (YHM).<br/>
          9300 Airport Road, #2206<br/>
          Mount Hope, ON L0R 1W0, Canada<br/><br/>
          Parking: For short and long term parking rates, please contact the <a href="https://flyhamilton.ca/parking-information/">Hamilton Airport</a> to learn more.
          </p>
        </div>
        <div className={styles.AirCanadaContentDesc}>
          <h3>Kitchener (YKF)</h3>
          <p>
            Landline picks up and drops off at the Region of Waterloo International Airport (YKF)<br/>
            4881 Fountain St N #1,<br/>
            Breslau, ON N0B 1 M0<br/><br/>
            Parking: For short and long term parking rates, please contact the <a href="https://www.waterlooairport.ca/en/passengers/parking.aspx">Region of Waterloo Airport</a> to learn more.
          </p>
        </div>
      </div>
    </div>
    
  )
}

export default AirCanadaContent
