import "./BookEarlyAndSave.scss";
import React, { useState, useEffect } from "react";

// Custom SVG Airplane Icon
const AirplaneIcon = ({ size = 16, color = "black" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
  >
    <path d="M22 16v-2l-8.5-5V3.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5V9L2 14v2l8.5-2.5V19L8 20.5V22l4-1 4 1v-1.5L13.5 19v-5.5z"></path>
  </svg>
);

export function BookEarlyAndSave() {
  const [prices, setPrices] = useState([]);

  const defaultPrices = [
    { id: 1, price: "35", location: "Denver <-> Northern Colorado Reg. Airport (FNL)", hasFreeParking: true, pillText: "Free Parking" },
    { id: 2, price: "32", location: "Denver <-> Colorado State University"},
    { id: 3, price: "32", location: "Denver <-> Harmony Transfer Center"},
    { id: 4, price: "39", location: "Minneapolis <-> Fargo, ND" },
    { id: 5, price: "36", location: "Minneapolis <-> Duluth, MN" },
    { id: 6, price: "39", location: "Minneapolis <-> La Crosse, WI", pillText: "★ Starts April 3" },
  ];

  useEffect(() => {
    setPrices(defaultPrices);
  }, []);

  // Smooth scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
  };

  return (
    <div className="BookEarlyAndSave">
      <div className="content-wrapper">
        <h2>Book Early and Save</h2>
        <div className="price-list">
          {prices.map((item) => {
            const [startLocation, endLocation] = item.location.split(" <-> ");
            return (
              <div className="price-row" key={item.id} onClick={scrollToTop}>
                <div className="location-connector">
                  <div className="location">
                    <div className="bullet"></div>
                    <div className="location-name">{endLocation}</div>
                  </div>
                  <div className="connector-line"></div>
                  <div className="location end-location">
                    <div className="endbullet">
                      <AirplaneIcon size={20} color="#000" />
                    </div>
                    <div className="location-name">{startLocation}</div>
                  </div>
                </div>
                {item.pillText && <div className="pill">{item.pillText}</div>}

                <div className="price-section">
                  <span className="from-text">From</span>
                  <span className="price">${item.price}</span>
                </div>
                <span className="icon-arrow">→</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
