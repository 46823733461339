import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link
import "./Cities.scss";

export function Cities() {
  const [activeTab, setActiveTab] = useState("Cities"); // State to manage active tab

  return (
    <div className="Cities">
      <div className="content-wrapper">
        {/* Tab Navigation */}
        <div className="tabs-wrapper">
          <button
            className={`tab-button ${activeTab === "Cities" ? "active" : ""}`}
            onClick={() => setActiveTab("Cities")}
          >
            Landline Cities
          </button>
          <button
            className={`tab-button ${activeTab === "Connections" ? "active" : ""}`}
            onClick={() => setActiveTab("Connections")}
          >
            Landline Connections
          </button>
        </div>

        {/* Tab Content */}
        {activeTab === "Cities" && (
          <div className="cities-we-serve-wrapper">
            <div className="cities-grid">
              <Link to="/denver" className="city-card">
                <h4>Denver, CO</h4>
                <p>Denver Airport</p>
              </Link>
              <Link to="/fort-collins" className="city-card">
                <h4>Fort Collins, CO</h4>
                <p>Colorado State University</p>
              </Link>
              <Link to="/fort-collins" className="city-card">
                <h4>Loveland, CO</h4>
                <p>Northern Colorado Regional Airport</p>
              </Link>
              <Link to="/fort-collins" className="city-card">
                <h4>Timnath, CO</h4>
                <p>Harmony Transfer Center</p>
              </Link>
              <Link to="/msp" className="city-card">
                <h4>Minneapolis, MN</h4>
                <p>Minneapolis-St. Paul International Airport</p>
              </Link>
              <Link to="/duluth" className="city-card">
                <h4>Duluth, MN</h4>
                <p>Duluth International Airport</p>
              </Link>
              <Link to="/fargo" className="city-card">
                <h4>Fargo, ND</h4>
                <p>Hector International Airport</p>
              </Link>
              <Link to="/lacrosse" className="city-card">
                <h4>La Crosse, WI</h4>
                <p>La Crosse Regional Airport</p>
              </Link>
            </div>
          </div>
        )}

        {activeTab === "Connections" && (
          <div className="routes-list-wrapper">
            <div className="routes-columns">
              <div className="routes-column">
                <h3 className="routes-title">Routes from Denver International Airport (DEN)</h3>
                <ul className="routes-list">
                  <li>
                    <Link to="/denver" className="route-item">
                      DEN Airport → Colorado State University (CSU)
                    </Link>
                  </li>
                  <li>
                    <Link to="/fort-collins" className="route-item">
                      Colorado State University (CSU) → DEN Airport
                    </Link>
                  </li>
                  <li>
                    <Link to="/denver" className="route-item">
                      DEN Airport → Harmony Transfer Center
                    </Link>
                  </li>
                  <li>
                    <Link to="/fort-collins" className="route-item">
                      Harmony Transfer Center → DEN Airport
                    </Link>
                  </li>
                  <li>
                    <Link to="/denver" className="route-item">
                      DEN Airport → Northern Colorado Regional Airport (FNL)
                    </Link>
                  </li>
                  <li>
                    <Link to="/fort-collins" className="route-item">
                      Northern Colorado Regional Airport (FNL) → DEN Airport
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="routes-column">
                <h3 className="routes-title">Routes from Minneapolis-St. Paul Airport (MSP)</h3>
                <ul className="routes-list">
                  <li>
                    <Link to="/msp" className="route-item">
                      MSP Airport → Fargo Airport
                    </Link>
                  </li>
                  <li>
                    <Link to="/fargo" className="route-item">
                      Fargo Airport → MSP Airport
                    </Link>
                  </li>
                  <li>
                    <Link to="/msp" className="route-item">
                      MSP Airport → Duluth Airport
                    </Link>
                  </li>
                  <li>
                    <Link to="/duluth" className="route-item">
                      Duluth Airport → MSP Airport
                    </Link>
                  </li>
                  <li>
                    <Link to="/lacrosse" className="route-item">
                    MSP Airport → La Crosse Airport
                    </Link>
                  </li>
                  <li>
                    <Link to="/lacrosse" className="route-item">
                      La Crosse Airport → MSP Airport
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
