import "./Footer.styles.scss"

import React from "react"
import { useQuery, gql } from "@apollo/client"

import {
  CONTACT_PAGE,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS
} from "../../../constants/routes"
import config from "../../../utils/config"
import TopMenuLogo from "../../atoms/TopMenuLogo"
import { Socials } from "../../socials/Socials"

const GET_FOOTER_LINKS = gql`
  query FooterLinksQuery {
    cities {
      nodes {
        slug
        title
        showInFooter {
          showInFooter
        }
      }
    }
  }
`

const FooterLinks = () => {
  const { loading, error, data } = useQuery(GET_FOOTER_LINKS)

  if (loading || error) {
    return null
  }

  const visibleNodes = data.cities.nodes.filter(node => {
    const shouldShowLink = node.showInFooter?.showInFooter?.[0] === "Yes"
    return shouldShowLink
  })

  return (
    <p>
      Premium Airport Shuttle serving: <a href="/landline/denver" className="footer-city-link">Denver</a>, <a href="/landline/fort-collins" className="footer-city-link">Fort Collins / Loveland</a>, <a href="/landline/msp" className="footer-city-link">Minneapolis</a>, <a href="/landline/duluth" className="footer-city-link">Duluth</a>, <a href="/landline/fargo" className="footer-city-link">Fargo</a>, and <a href="/landline/lacrosse" className="footer-city-link">La Crosse</a>
    </p>
    // <p>
    //   Premium Airport Shuttle serving:{" "}
    //   {visibleNodes.map((node, i) => {
    //     return (
    //       <>
    //         <a
    //           key={node.slug}
    //           href={`/${node.slug}/`}
    //           style={{ color: "#fab500", textDecoration: "none" }}
    //         >
    //           {node.title}
    //           {i < visibleNodes.length - 1 ? "," : ""}
    //         </a>{" "}
    //       </>
    //     )
    //   })}
    // </p>
  )
}

const Footer = () => {
  return (
    <div className="container">
      <div className="line" />
      <div className="footer-links-wrapper">
        <div className="footerLogoColumn">
          <div>
            <TopMenuLogo />
          </div>
          <a href="tel:+18884281149" className="phoneplus">+1 <span className="phone">(888)-428-1149</span></a>
        </div>
        <div>
          <div className="links">
			  <a href="https://landline.com/landline/charters/">Charters</a>
            <a href="https://landlineco.com/" target="_blank">The Landline Co</a>
            <a href={`${config.LANDLINE_URL}${CONTACT_PAGE}`}>Contact</a>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Socials />
      </div>
      <div className="city-links">
        <FooterLinks />
      </div>
      <div className="copyright">
        &copy;
        {new Date().getFullYear()} The Landline Company. <br /> All Rights Reserved.
      </div>
      {/* <Privacy><Link to={"https://landline.com/terms-and-conditions"}>Terms and Conditions</Link> | <Link to={"https://landline.com/privacy-policy/"}>Privacy Policy</Link></Privacy> */}
      <div className="privacy">
        <a href={`${config.LANDLINE_URL}${TERMS_AND_CONDITIONS}`}>Terms and Conditions</a>{" "}
        <span style={{ color: "#FAB500" }}> | </span>
        <a href={`${config.LANDLINE_URL}${PRIVACY_POLICY}`}>Privacy Policy</a>{" "}
      </div>
    </div>
  )
}

export default Footer
