import React from "react"

import { NotificationConsumer } from "ibe-components"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import TabNavbarLayout from "../../layouts/TabNavbarLayout"

const BasicPage = (props) => {

  return (
    <NotificationConsumer>
      {({ actions }) => {
        return (
          <ErrorBoundary>
            <TabNavbarLayout
            pageLayoutBackground="#FFF"
              render={() => {

                return (
                  <>
                    {props.children}
                  </>
                )
              }}
            />
          </ErrorBoundary>
        )
      }}
    </NotificationConsumer>
  )
}


export default BasicPage