import * as React from "react"
import { BsChevronUp } from "@react-icons/all-files/bs/BsChevronUp"
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown"
import * as styles from "./QuestionAndAnswer.module.scss"

const QuestionAndAnswer = ({ item, questionBgColor }) => {
  const [showAnswer, setShowAnser] = React.useState(false)


  const toggleAnswer = () => {
    setShowAnser(!showAnswer)
  }
  return (
    <div className={styles.qna}>
      <div
        role="button"
        className={`${styles.question} ${styles[questionBgColor]}`}
        onClick={toggleAnswer}
        onKeyDown={() => {}}
      >
        <h3 className={styles.questionTitle}>{item.q}</h3>
        {showAnswer ? (
          <BsChevronUp className={styles.plusAndMinusIcons} />
        ) : (
          <BsChevronDown className={styles.plusAndMinusIcons} />
        )}
      </div>
      {showAnswer ? (
        <>
          <div
            className={styles.answerWrapper}
          >{item.a}</div>
        </>
      ) : null}
    </div>
  )
}
export default QuestionAndAnswer
