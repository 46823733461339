import React, { useState } from "react"
import SchedulePage from ".."
import ScheduleHeader from "../Header"
import OurService from "../OurService"
import ModuleTitle from "../../../atoms/SchedulePages/ModuleTitle"
import { ScheduleLocation } from "../Locations"
import { Schedule, ScheduleTable } from "../Schedule"
import Search from "../../HomePage/Search"
import './styles.scss'

import { DuluthLocation, FargoLocation, LaCrosseLocation } from "./locations"
import { MSPLocationService, MSPMeta, MSPService } from "./metadata"
import LocationService from "../Service"
import DuluthData from "../Duluth/schedule"
import FargoData from "../Fargo/schedule"
import { LaCrosseWeeklyData, generateLaCrosseData } from '../LaCrosse/schedule'

const MSPSchedule = ({ history }) => {
  const [selectedWeek, setSelectedWeek] = useState(LaCrosseWeeklyData[0]);
  const [lacrosseSchedule, setLacrosseSchedule] = useState(() => 
      generateLaCrosseData(LaCrosseWeeklyData[0])
  );

  const handleWeekChange = (week) => {
      setSelectedWeek(week);
      setLacrosseSchedule(generateLaCrosseData(week));
  };

  const currentIndex = LaCrosseWeeklyData.findIndex(
      week => week.start === selectedWeek.start && week.end === selectedWeek.end
  );

  const handlePrevious = () => {
      if (currentIndex > 0) {
          handleWeekChange(LaCrosseWeeklyData[currentIndex - 1]);
      }
  };

  const handleNext = () => {
      if (currentIndex < LaCrosseWeeklyData.length - 1) {
          handleWeekChange(LaCrosseWeeklyData[currentIndex + 1]);
      }
  };

  const goToFargo = () => {
    history.push("/fargo")
  }

  const goToDuluth = () => {
    history.push("/duluth")
  }

  const goToLacrosse = () => {
    history.push("/lacrosse")
  }

  return (
    <SchedulePage>
      <div className="schedule-search-form">
        <Search history={history} remoteCallSearchComponent={true} />
      </div>
      <div className="schedule-page">
        <ScheduleHeader metadata={MSPMeta.header} />

        <ModuleTitle title="Duluth (DLH)" />
        <ScheduleLocation data={DuluthLocation} onButtonClick={goToDuluth} />
        <Schedule>
          <ScheduleTable schedule={DuluthData.to} />
          <ScheduleTable schedule={DuluthData.from} />
        </Schedule>

        <ModuleTitle title="Fargo (FAR)" />
        <ScheduleLocation data={FargoLocation} onButtonClick={goToFargo} />
        <Schedule>
          <ScheduleTable schedule={FargoData.to} />
          <ScheduleTable schedule={FargoData.from} />
        </Schedule>

        <ModuleTitle title="La Crosse (LSE)" />
        <ScheduleLocation data={LaCrosseLocation} onButtonClick={goToLacrosse} />
        <div className="week-selector">
            <button 
                onClick={handlePrevious}
                disabled={currentIndex === 0}
                className="nav-button"
                aria-label="Previous week"
            >
                <svg 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path 
                        d="M15 18L9 12L15 6" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            
            <div className="week-display">
                <span className="week-date">
                    Week of {new Date(selectedWeek.start).toLocaleDateString('en-US', { 
                        month: 'long', 
                        day: 'numeric' 
                    })}
                </span>
                <span className="week-count">
                    {new Date(selectedWeek.start).toLocaleDateString('en-US', { 
                        month: 'numeric', 
                        day: 'numeric'
                    })} to {new Date(selectedWeek.end).toLocaleDateString('en-US', { 
                        month: 'numeric', 
                        day: 'numeric'
                    })} 
                </span>
            </div>

            <button 
                onClick={handleNext}
                disabled={currentIndex === LaCrosseWeeklyData.length - 1}
                className="nav-button"
                aria-label="Next week"
            >
                <svg 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path 
                        d="M9 6L15 12L9 18" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
        </div>
        <Schedule>
            <ScheduleTable schedule={lacrosseSchedule.to} />
            <ScheduleTable schedule={lacrosseSchedule.from} />

        </Schedule>

        <OurService service={MSPService} />

        <LocationService service={MSPLocationService} />
      </div>
    </SchedulePage>
  )
}

export default MSPSchedule