// schedule.js
const dayMap = {
    1: "MON", 2: "TUE", 3: "WED", 4: "THU", 5: "FRI", 6: "SAT", 7: "SUN"
 };
 
 const FlightSchedule = [
    { eff: "07-Apr-25", dis: "14-Apr-25", freq: "1…...", orig: "LSE", dept: "1035", dest: "MSP", arr: "1305" },
    { eff: "21-Apr-25", dis: "28-Apr-25", freq: "1…...", orig: "LSE", dept: "1010", dest: "MSP", arr: "1240" },
    { eff: "05-May-25", dis: "02-Jun-25", freq: "1…...", orig: "LSE", dept: "1125", dest: "MSP", arr: "1355" },
    { eff: "09-Jun-25", dis: "09-Jun-25", freq: "1…...", orig: "LSE", dept: "1120", dest: "MSP", arr: "1350" },
    { eff: "16-Jun-25", dis: "02-Sep-25", freq: "1…...", orig: "LSE", dept: "1130", dest: "MSP", arr: "1400" },
    { eff: "03-Apr-25", dis: "10-Apr-25", freq: "…4…", orig: "LSE", dept: "1050", dest: "MSP", arr: "1320" },
    { eff: "17-Apr-25", dis: "24-Apr-25", freq: "…4…", orig: "LSE", dept: "1040", dest: "MSP", arr: "1310" },
    { eff: "01-May-25", dis: "15-May-25", freq: "…4…", orig: "LSE", dept: "1125", dest: "MSP", arr: "1355" },
    { eff: "22-May-25", dis: "22-May-25", freq: "…4…", orig: "LSE", dept: "1120", dest: "MSP", arr: "1350" },
    { eff: "29-May-25", dis: "02-Sep-25", freq: "…4…", orig: "LSE", dept: "1130", dest: "MSP", arr: "1400" },
    { eff: "04-Apr-25", dis: "04-Apr-25", freq: "….5..", orig: "LSE", dept: "1110", dest: "MSP", arr: "1340" },
    { eff: "11-Apr-25", dis: "25-Apr-25", freq: "….5..", orig: "LSE", dept: "1035", dest: "MSP", arr: "1305" },
    { eff: "02-May-25", dis: "30-May-25", freq: "….5..", orig: "LSE", dept: "1125", dest: "MSP", arr: "1355" },
    { eff: "06-Jun-25", dis: "02-Sep-25", freq: "….5..", orig: "LSE", dept: "1130", dest: "MSP", arr: "1400" },
    { eff: "05-Apr-25", dis: "05-Apr-25", freq: "…..6.", orig: "LSE", dept: "1115", dest: "MSP", arr: "1345" },
    { eff: "12-Apr-25", dis: "12-Apr-25", freq: "…..6.", orig: "LSE", dept: "1020", dest: "MSP", arr: "1250" },
    { eff: "19-Apr-25", dis: "26-Apr-25", freq: "…..6.", orig: "LSE", dept: "1050", dest: "MSP", arr: "1320" },
    { eff: "03-May-25", dis: "31-May-25", freq: "…..6.", orig: "LSE", dept: "1210", dest: "MSP", arr: "1440" },
    { eff: "07-Jun-25", dis: "02-Sep-25", freq: "…..6.", orig: "LSE", dept: "1130", dest: "MSP", arr: "1400" },
    { eff: "06-Apr-25", dis: "06-Apr-25", freq: "…...7", orig: "LSE", dept: "1120", dest: "MSP", arr: "1350" },
    { eff: "13-Apr-25", dis: "13-Apr-25", freq: "…...7", orig: "LSE", dept: "1055", dest: "MSP", arr: "1325" },
    { eff: "20-Apr-25", dis: "20-Apr-25", freq: "…...7", orig: "LSE", dept: "1030", dest: "MSP", arr: "1300" },
    { eff: "27-Apr-25", dis: "27-Apr-25", freq: "…...7", orig: "LSE", dept: "1020", dest: "MSP", arr: "1250" },
    { eff: "04-May-25", dis: "25-May-25", freq: "…...7", orig: "LSE", dept: "1125", dest: "MSP", arr: "1355" },
    { eff: "01-Jun-25", dis: "02-Sep-25", freq: "…...7", orig: "LSE", dept: "1130", dest: "MSP", arr: "1400" },
    { eff: "07-Apr-25", dis: "21-Apr-25", freq: "1......", orig: "MSP", dept: "1630", dest: "LSE", arr: "1900" },
    { eff: "28-Apr-25", dis: "28-Apr-25", freq: "1......", orig: "MSP", dept: "1710", dest: "LSE", arr: "1940" },
    { eff: "05-May-25", dis: "12-May-25", freq: "1......", orig: "MSP", dept: "1555", dest: "LSE", arr: "1825" },
    { eff: "19-May-25", dis: "26-May-25", freq: "1......", orig: "MSP", dept: "1625", dest: "LSE", arr: "1855" },
    { eff: "02-Jun-25", dis: "02-Jun-25", freq: "1......", orig: "MSP", dept: "1610", dest: "LSE", arr: "1840" },
    { eff: "09-Jun-25", dis: "02-Sep-25", freq: "1......", orig: "MSP", dept: "1550", dest: "LSE", arr: "1820" },
    { eff: "03-Apr-25", dis: "10-Apr-25", freq: "…4…", orig: "MSP", dept: "1705", dest: "LSE", arr: "1935" },
    { eff: "17-Apr-25", dis: "17-Apr-25", freq: "…4…", orig: "MSP", dept: "1600", dest: "LSE", arr: "1830" },
    { eff: "24-Apr-25", dis: "24-Apr-25", freq: "…4…", orig: "MSP", dept: "1615", dest: "LSE", arr: "1845" },
    { eff: "01-May-25", dis: "01-May-25", freq: "…4…", orig: "MSP", dept: "1555", dest: "LSE", arr: "1825" },
    { eff: "08-May-25", dis: "08-May-25", freq: "…4…", orig: "MSP", dept: "1625", dest: "LSE", arr: "1855" },
    { eff: "15-May-25", dis: "15-May-25", freq: "…4…", orig: "MSP", dept: "1545", dest: "LSE", arr: "1815" },
    { eff: "22-May-25", dis: "22-May-25", freq: "…4…", orig: "MSP", dept: "1620", dest: "LSE", arr: "1850" },
    { eff: "29-May-25", dis: "02-Sep-25", freq: "…4…", orig: "MSP", dept: "1550", dest: "LSE", arr: "1820" },
    { eff: "04-Apr-25", dis: "30-May-25", freq: "….5..", orig: "MSP", dept: "1625", dest: "LSE", arr: "1855" },
    { eff: "06-Jun-25", dis: "02-Sep-25", freq: "….5..", orig: "MSP", dept: "1550", dest: "LSE", arr: "1820" },
    { eff: "05-Apr-25", dis: "05-Apr-25", freq: "…..6.", orig: "MSP", dept: "1635", dest: "LSE", arr: "1905" },
    { eff: "12-Apr-25", dis: "12-Apr-25", freq: "…..6.", orig: "MSP", dept: "1640", dest: "LSE", arr: "1910" },
    { eff: "19-Apr-25", dis: "26-Apr-25", freq: "…..6.", orig: "MSP", dept: "1600", dest: "LSE", arr: "1830" },
    { eff: "03-May-25", dis: "02-Sep-25", freq: "…..6.", orig: "MSP", dept: "1550", dest: "LSE", arr: "1820" },
    { eff: "06-Apr-25", dis: "06-Apr-25", freq: "…...7", orig: "MSP", dept: "1625", dest: "LSE", arr: "1855" },
    { eff: "13-Apr-25", dis: "13-Apr-25", freq: "…...7", orig: "MSP", dept: "1630", dest: "LSE", arr: "1900" },
    { eff: "20-Apr-25", dis: "20-Apr-25", freq: "…...7", orig: "MSP", dept: "1635", dest: "LSE", arr: "1905" },
    { eff: "27-Apr-25", dis: "27-Apr-25", freq: "…...7", orig: "MSP", dept: "1620", dest: "LSE", arr: "1850" },
    { eff: "04-May-25", dis: "02-Sep-25", freq: "…...7", orig: "MSP", dept: "1600", dest: "LSE", arr: "1830" }
  ];
  
 
  const formatTime = (timeStr) => `${timeStr.slice(0, -2)}:${timeStr.slice(-2)}`;

  // Utility to add/subtract minutes from a time string and format it properly with a colon
  const adjustTime = (timeStr, minutes) => {
      const hours = parseInt(timeStr.slice(0, -2));
      const mins = parseInt(timeStr.slice(-2));
      const date = new Date();
      date.setHours(hours, mins + minutes, 0);
      const adjustedHours = date.getHours().toString().padStart(2, "0");
      const adjustedMinutes = date.getMinutes().toString().padStart(2, "0");
      return `${adjustedHours}:${adjustedMinutes}`;
  };
  
  const getWeeklyItinerary = (start, end, origin, direction) => {
    const weeklyFlights = FlightSchedule.filter((flight) => {
        const weekStart = new Date(start);
        const weekEnd = new Date(end);
        const flightStart = new Date(flight.eff);
        const flightEnd = new Date(flight.dis);

        return (
            flight.orig === origin &&
            weekStart <= flightEnd &&
            weekEnd >= flightStart
        );
    });

    const daysWithService = new Map();

    weeklyFlights.forEach((flight) => {
        // For flights to LaCrosse (from MSP)
        // MSA departs 20 min before MSB, MSB is original departure, LSE is original arrival
        const msaTime = direction === "to" 
            ? adjustTime(flight.dept, -20)  // MSA departs 20 min before MSB
            : adjustTime(flight.arr, 15);   // MSA arrives 15 min after MSB

        const times = direction === "to" ? [
            msaTime,                    // MSA departure (20 min before MSB)
            formatTime(flight.dept),    // MSB departure (original MSP time)
            formatTime(flight.arr)      // LSE arrival
        ] : [
            formatTime(flight.dept),    // LSE departure
            formatTime(flight.arr),     // MSB arrival (original MSP time)
            msaTime                     // MSA arrival (15 min after MSB)
        ];

        if (flight.freq.includes("1"))
            daysWithService.set(1, [dayMap[1], ...times]);
        if (flight.freq.includes("4"))
            daysWithService.set(4, [dayMap[4], ...times]);
        if (flight.freq.includes("5"))
            daysWithService.set(5, [dayMap[5], ...times]);
        if (flight.freq.includes("6"))
            daysWithService.set(6, [dayMap[6], ...times]);
        if (flight.freq.includes("7"))
            daysWithService.set(7, [dayMap[7], ...times]);
    });

    return Array.from(daysWithService.values()).sort((a, b) => {
        const dayA = Object.entries(dayMap).find(([_, val]) => val === a[0])[0];
        const dayB = Object.entries(dayMap).find(([_, val]) => val === b[0])[0];
        return parseInt(dayA) - parseInt(dayB);
    });
};
  const generateLaCrosseData = (week) => {
      if (!week?.start || !week?.end) {
          console.error("Invalid week object provided:", week);
          return {
              from: { title: "", stops: [], itinerary: [] },
              to: { title: "", stops: [], itinerary: [] },
          };
      }
  
      const itineraryFrom = getWeeklyItinerary(week.start, week.end, "LSE", "from");
      const itineraryTo = getWeeklyItinerary(week.start, week.end, "MSP", "to");
  
      return {
          from: {
              title: "From LaCrosse",
              stops: [
                  { type: "", name: "DAYS" },
                  { type: "Depart", name: "LSE" },
                  { type: "Arrive", name: "MSP T2" },
                  { type: "Arrive", name: "MSP T1" }, // New column for MSA stop
              ],
              itinerary: itineraryFrom,
          },
          to: {
              title: "To LaCrosse",
              stops: [  
                  { type: "", name: "DAYS" },
                  { type: "Depart", name: "MSP T1" }, // New column for MSA stop
                  { type: "Depart", name: "MSP T2" },
                  { type: "Arrive", name: "LSE" },
              ],
              itinerary: itineraryTo,
          },
      };
  };
  
 
 const generateWeeks = () => {
    // Find earliest and latest dates in schedule
    const dates = FlightSchedule.flatMap(flight => [new Date(flight.eff), new Date(flight.dis)]);
    const earliestDate = new Date(Math.min(...dates));
    const latestDate = new Date(Math.max(...dates));

    // Get to the Monday of the earliest week
    const firstMonday = new Date(earliestDate);
    firstMonday.setDate(firstMonday.getDate() - firstMonday.getDay() + 1); // Move to Monday

    const weeks = [];
    let currentMonday = new Date(firstMonday);

    while (currentMonday <= latestDate) {
        // Create Sunday by adding 6 days to Monday
        const sunday = new Date(currentMonday);
        sunday.setDate(sunday.getDate() + 6);

        weeks.push({
            start: currentMonday.toLocaleDateString('en-GB', { 
                day: '2-digit', 
                month: 'short', 
                year: '2-digit'
            }).replace(/ /g, '-'),
            end: sunday.toLocaleDateString('en-GB', { 
                day: '2-digit', 
                month: 'short', 
                year: '2-digit'
            }).replace(/ /g, '-')
        });

        // Move to next Monday
        currentMonday = new Date(currentMonday);
        currentMonday.setDate(currentMonday.getDate() + 7);
    }

    return weeks;
};

const LaCrosseWeeklyData = generateWeeks();

 export { LaCrosseWeeklyData, generateLaCrosseData };