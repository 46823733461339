import React from 'react';
import './MotorCoach.scss';

export function MotorCoach() {
  return (
    <div className="MotorCoach">
      <div className="content-wrapper">
        <div className="left-column">
          <div className="text-content">
            <p className="description">
            Get to <a className="mclink" href="/landline/msp">MSP</a> and <a className="mclink" href="/landline/denver">DEN</a> the easy way. 
            Landline's motorcoach and airport shuttle service saves you time and money with convenient pickup <a className="mclink" href="/landline/locations">spots</a> and flexible <a className="mclink" href="/landline/locations">schedules</a>. 
            Sit back, relax - we'll handle the bags.
            </p>
            
            <a href="/how-it-works" className="learn-more-link">
              Learn more about how it works
              <svg 
                className="arrow-icon"
                width="20" 
                height="20" 
                viewBox="0 0 24 24" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path 
                  d="M5 12H19M19 12L12 5M19 12L12 19" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </div>

          <div className="timeline-wrapper">
  <div className="step-column">
    <div className="caption">Book Trip</div>
  </div>
  <div className="lineSeparator"></div>
  <div className="step-column">
    <div className="caption">Check In</div>
  </div>
  <div className="lineSeparator"></div>
  <div className="step-column">
    <div className="caption">Enjoy the Ride</div>
  </div>
</div>

        </div>

        <div className="image-container">
        
        </div>
      </div>
    </div>
  );
}