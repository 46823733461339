import "./Promotion.scss"

import React from "react"

import employee from "../../../media/employee.png"
import luggagecheck from "../../../media/luggagecheck-1.png"
import money from "../../../media/money.png"
import stars from "../../../media/star.png"

const Promotion = () => {
  return (
    <div className="Wrapper--Promotion">
  <div className="Row--Promotion">
    <div className="Quater--Promotion">
      <div className="iconWrapper">
        <img src={stars} alt="star" />
      </div>
      <div className="contentWrapper">
        <h3>Comfort</h3>
        <p>Our vehicles are uniquely designed to make travel more relaxing and comfortable.</p>
      </div>
    </div>
    <div className="Quater--Promotion">
      <div className="iconWrapper">
        <img src={luggagecheck} alt="luggage check" />
      </div>
      <div className="contentWrapper">
        <h3>Convenience</h3>
        <p>Our nonstop service to/from MSP in Minnesota and DEN in Colorado is fast, easy, and stress-free.</p>
      </div>
    </div>
    <div className="Quater--Promotion">
      <div className="iconWrapper">
        <img src={money} alt="money" />
      </div>
      <div className="contentWrapper">
        <h3>Value</h3>
        <p>Landline offers premium travel without the high costs, while saving you money on parking.</p>
      </div>
    </div>
    <div className="Quater--Promotion">
      <div className="iconWrapper">
        <img src={employee} alt="employee" />
      </div>
      <div className="contentWrapper">
        <h3>Service</h3>
        <p>Friendly agents at pick-up/drop-off locations are there to make your trip a success.</p>
      </div>
    </div>
  </div>
</div>

  )
}

export default Promotion
