import PropTypes from "prop-types"
import React, { useCallback, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useDispatch } from "react-redux"

import redirectActions from "../../../redux/redirect/actions"
import reservationActions from "../../../redux/reservations/actions"
import TabNavbarLayout from "../../layouts/TabNavbarLayout"
import Search from "./Search"
import { MotorCoach } from "../../promotionals/MotorCoach"


const Home = ({ history }) => {
  const dispatch = useDispatch()

  const setRedirectURL = useCallback(url => dispatch(redirectActions.setRedirectURL(url)), [
    dispatch,
  ])
  const clearSearch = useCallback(() => dispatch(reservationActions.clearSearch()), [dispatch])

  useEffect(() => {
    setRedirectURL("/")
    clearSearch()
  }, [])

  
  return (
    <div className="home-page">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.landline.com"
          data-baseprotocol="https:"
          data-basehost="www.landline.com"
        />
         <meta 
          name="viewport" 
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=contain"
        />
      </Helmet>
     
      <TabNavbarLayout
        beforeTab={() => 
          <div className="custom-header-banner">
          <p className="custom-header-text">The best connection to your flight at an incredible price</p>
          <p className="custom-header-subtite">Premium airport shuttle service <span>to DEN & MSP</span></p>
          </div>
        }      
        render={() => (
          <>
            <Search history={history} />


          </>
        )}
      />
    </div>
  )
}

export default Home

Home.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}
