import * as React from "react"
import * as styles from "./ACNavigation.module.scss"

const AirCanadaNavigation = ({ data }) => {
    return (
      <div className={styles.AirCanadaNavigation} id="AirCanadaNavigation">
        <a href="#AirCanadaHowItWorks" className={styles.AirCanadaNavigationItem}>
          <div>
          How It Works
        </div>
        </a>
        <a href="#AirCanadaLocations" className={styles.AirCanadaNavigationItem}>
        <div>
          Locations
        </div>
        </a>
        <a href="#AirCanadaFAQs" className={styles.AirCanadaNavigationItem}>
        <div>
          FAQs
        </div>
        </a>
        <a href="/aircanada-terms-conditions" className={styles.AirCanadaNavigationItem}>
        <div>
          Terms & Conditions
        </div>
        </a>
      </div>
    )
  }
  
  export default AirCanadaNavigation